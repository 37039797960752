import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../../../components/seo"

import Menu from "../../../components/ug-menu/menu"

const MenuPage = () => {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        brand(id: "7") {
          id
          title
          slug
          className
          image {
            url
          }
          outlets {
            id
            title
            menus (sort: "order") {
              title
              order
              file {
                url
              }
            }
          }
        }
      }
    }
  `)
  const blocks = data.strapi.brand
  const menus = blocks?.outlets.find(x => x.title === "Pondok Indah Mall R")

  return (
    <>
      <SEO
        title={`${blocks.title} Menu`}
        description="A classic italian trattoria, serving handmade pastas and authentic pizzas accompanied by italian cocktails and an extensive wine list."
      />
      <Helmet>
        <body className={`bd-menu-landing ${blocks.className}`} />
      </Helmet>

      <Menu
        loaderImg={`https://qrweb-urea-lss9.antikode.dev${blocks.image[1].url}`}
        loaderAlt={blocks.title}
        headerImg={`https://qrweb-urea-lss9.antikode.dev${blocks.image[0].url}`}
        headerAlt={blocks.title}
        headerOutlet=""
        menus={
          menus?.menus?.map((value, i) => {
            return {
              title: value.title,
              link: value.file.url
            }
          })
        }
        paymentLink="http://hungree.id/cashier"
        slug={blocks.slug}
        branch="Pondok Indah Mall"
      />
    </>
  )
}

export default MenuPage
